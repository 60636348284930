





































import Spinner from '@/components/atoms/Spinner.vue'
import menuStore from '@/store/Menu'
import organizationUsersStore, { ErrorMsg } from '@/store/OrganizationUsers'
import contractStore from '@/store/Contracts'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: { Spinner },
})
export default class extends Vue {
  orgLoginId = ''
  email = ''
  password = ''
  isLoading = false
  error = ''

  mounted(): void {
    menuStore.setInvisible()
    this.initInputField()
  }

  initInputField(): void {
    const queryOrgLoginId = this.$route.query.orgLoginId
    if (queryOrgLoginId === undefined || Array.isArray(queryOrgLoginId)) {
      return
    }
    this.orgLoginId = queryOrgLoginId
  }

  get isExpiration(): boolean {
    return JSON.stringify(contractStore.latestContractForNoContract) === '{}'
  }

  get isOrgOwner(): boolean {
    return organizationUsersStore.isOrgOwner
  }

  async submit(): Promise<void> {
    this.isLoading = true
    try {
      await organizationUsersStore.login({ orgLoginId: this.orgLoginId, email: this.email, password: this.password })
      // TODO: Home画面遷移時に認証チェックがかかるので、ここで契約外画面に飛ばす必要はないと思われる
      await contractStore.fetchLatestContractForNoContract()
      await organizationUsersStore.getCurrentRole()
      if (this.isExpiration && this.isOrgOwner) {
        this.$router.replace({ name: 'ExpirationOfContract' })
        return
      }
      this.$router.replace({ name: 'Home' })
    } catch (e) {
      switch (e) {
        case ErrorMsg.responseError(400):
          this.error = 'ログインに失敗しました。入力内容を再確認してください。'
          return
        case ErrorMsg.responseError(401):
          this.error = 'ユーザアカウントが存在しません。'
          return
        case ErrorMsg.responseError(402):
          this.error = '組織アカウントが存在しません。'
          return
        case ErrorMsg.responseError(403):
          this.error = 'この組織の閲覧権限がありません。'
          return
        default:
          this.error = 'ログインに失敗しました。通信環境を確認してください。'
          return
      }
    } finally {
      this.isLoading = false
    }
  }
}
